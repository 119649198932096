import React, { useState, useEffect } from 'react';
import { useParams } from "react-router";
import Swal from 'sweetalert2';
import { request } from '../../constants/constants';
import { Button, Loader, TextField} from "monday-ui-react-core";
import ItemsTable from '../../Components/ItemsTable/ItemsTable'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../index.css"
import CreatableSelect from 'react-select/creatable';

function UsageExternal() {

    const [state, setState] = useState({
        is_loading: true,
        invalid_form: false,
        selectedClient: null,
        selectedDate: null,
        items: [],
        suppliers: [],
        clients: [],
        products: [],
        opportunity: '',
    });

    let { id } = useParams();

    useEffect(() => {

        request('GET', '/external/usage/' + id).then(res => {

            console.log("res", res) 
            const suppliers = res.data.suppliers;
            const products = res.data.products;
            const clients = res.data.clients;
            setState(state => ({ ...state, is_loading: false, suppliers: suppliers, clients: clients, products: products}))
        }).catch(error => {
            console.error("get-external-usage" + error);
            setState(state => ({ ...state, invalid_form: true, is_loading: false }))
        })

    }, [id]);
    
    const submitUsage = (event) => {
        event.preventDefault();
        if (checkSubmissionDetails()) {

            let data = {
                client: state.selectedClient,
                opportunity: state.opportunity,
                date: state.selectedDate,
                items: state.items,
            }

            setState({...state, is_loading: true});
            request('POST', '/external/usage/' + id, data).then(res => {
                Swal.fire({ title: "Success", text: res.data.message, type: "success", timer: 5000 });
                setState({ ...state, selectedClient: null, opportunity: '', items: [], is_loading: false});
            }).catch(err => {
                Swal.fire({ title: "Error", text: err.response.data.message, type: "error", timer: 5000 });
                setState({ ...state, is_loading: false });
            });
        } else {
            Swal.fire({ title: "Error", text: "Please complete all required fields", type: "warning", timer: 5000 });
        }
    }
    
    const checkSubmissionDetails = () => {
        if (state.items.length === 0) {
            return false;
        }

        if (state.selectedClient === null) {
            return false;
        }

        if (state.selectedDate === null) {
            return false;
        }

        for (var i = 0; i < state.items.length; i++) {
            let item = state.items[i];
            if (item.product === null || item.quantity === null || item.supplier === null) {
                return false;
            }
        }

        return true;
    }

    const handleQuantityChange = (index, value) => {
        let currentItems = state.items;
        currentItems[index].quantity = value;
        setState({...state, items: currentItems });
    }

    const handleNoteChange = (index, value) => {
        let currentItems = state.items;
        currentItems[index].notes = value;
        setState({...state, items: currentItems });
    }

    const handleClientChange = ( value ) => {
        setState({...state, selectedClient: value});
    }

    const handleOpportunityChange = ( value ) => {
        setState({...state, opportunity: value});
    }

    const handleDateChange = (date) => {
        setState({ ...state, selectedDate: date.toISOString().split('T')[0]});
    }

    const handleUploadFile = async(index, event) => {
        let currentItems = state.items;
        console.log(event.target.files[0])
        const file = await getBase64(event.target.files[0]);
        console.log(file);
        const {base64, name} = file;

        currentItems[index].file = {base64, name}
        setState({...state, items: currentItems });
    }

    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            baseURL = reader.result;
            resolve({base64: baseURL, name: file.name});
          };
          console.log(fileInfo);
        });
      };

    const addNewItem = () => {
        let currentItems = state.items;
        currentItems.push({ product: null, quantity: 1, supplier: null});
        setState({...state, items: currentItems });
    }

    const itemProductChange = (index, value) => {
        let currentItems = state.items;
        currentItems[index].product = value;
        if (value.supplier) {
            const supplier = state.suppliers.find(supplier => { return supplier.value === `${value.supplier}` } )
            currentItems[index].supplier = supplier;
        }
        setState({...state, items: currentItems });
    }

    const removeItem = (index) => {
        let currentItems = state.items;
        currentItems.splice(index, 1);
        setState({...state, items: currentItems });
    }

    const handleCreateNewProduct = (index, value) => {
        let data = {
            itemName: value,
        }

        setState({...state, is_loading: true});
        request('POST', '/external/create-product/' + id, data).then(res => {
            Swal.fire({ title: "Success", text: res.data.message, type: "success", timer: 5000 });
            
            let currentItems = state.items;
            currentItems[index].product = {value: res.data.id, label: value};
            
            let products = state.products;
            products.push({value: res.data.id, label: value});
            setState({...state, items: currentItems, products: products, is_loading: false  });

        }).catch(err => {
            Swal.fire({ title: "Error", text: err.response.data.message, type: "error", timer: 5000 });
            setState({ ...state, is_loading: false });
        });
    }

    const itemSupplierChange = (index, value) => {
        let currentItems = state.items;
        currentItems[index].supplier = value;
        setState({...state, items: currentItems });
    }

    const handleCreateNewSupplier = (index, value) => {
        let data = {
            itemName: value,
        }

        setState({...state, is_loading: true});
        request('POST', '/external/create-supplier/' + id, data).then(res => {
            Swal.fire({ title: "Success", text: res.data.message, type: "success", timer: 5000 });
            
            let currentItems = state.items;
            currentItems[index].supplier = {value: res.data.id, label: value};
            
            let suppliers = state.suppliers;
            suppliers.push({value: res.data.id, label: value});
            setState({...state, items: currentItems, suppliers: suppliers, is_loading: false  });

        }).catch(err => {
            Swal.fire({ title: "Error", text: err.response.data.message, type: "error", timer: 5000 });
            setState({ ...state, is_loading: false });
        });
    }

    const handleCreateNewClient = (value) => {
        let data = {
            itemName: value,
        }

        setState({...state, is_loading: true});
        request('POST', '/external/create-client/' + id, data).then(res => {
            Swal.fire({ title: "Success", text: res.data.message, type: "success", timer: 5000 });
            let selectedClient = {value: res.data.id, label: value};
            
            let clients = state.clients;
            console.log(state.clients);
            console.log(clients);
            clients.push({value: res.data.id, label: value});
            setState({...state, selectedClient: selectedClient, clients: clients, is_loading: false  });

        }).catch(err => {
            console.log(err);
            Swal.fire({ title: "Error", text: err.response.data.message, type: "error", timer: 5000 });
            setState({ ...state, is_loading: false });
        });
    }

    if (state.is_loading) {
        return (
            <div className="container">
                <div class="row h-100">
                    <div class="col-sm-12 my-auto">
                        <div className='mx-auto' style={{ height: '50px', width: '200px', paddingTop: '250px' }}>
                            <div className='d-flex justify-content-center' style={{
                                color: "var(--primary-color)"
                            }}>
                                <Loader size={50} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (state.invalid_form) {
        return (
            <div className="container">
                <div class="row h-100">
                    <div class="col-sm-12 my-auto">
                        <div className='mx-auto' style={{ height: '50px', width: '200px', paddingTop: '250px' }}>
                            <p>Invalid Form</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {

        return (
            
            <div className="container">
                <div>
                    <h3 className="text-center mt-4 md-5">Add to Shopping List</h3>
                    <div className="row mt-2">
                        <div className="col-6 mb-2 mx-auto">
                            <span>Client</span>
                            <CreatableSelect
                                isClearable
                                onChange={handleClientChange} 
                                onCreateOption={handleCreateNewClient}
                                options={state.clients}
                                noOptionsMessage={() => { return "No clients found" }}
                                value={state.selectedClient} 
                            />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-6 mb-2 mx-auto">
                            <TextField title="Opportunity Name" placeholder="Opportunity name" size={TextField.sizes.LARGE} onChange={handleOpportunityChange} />
                        </div>
                    </div>
                    <br />
                    <div className="row text-center">
                        <div className="col-6 mb-2 mx-auto">
                            <span>Usage Date</span>
                            <DatePicker inline onChange={handleDateChange} />
                        </div>
                    </div>
                    <br />

                    <ItemsTable
                        items={state.items}
                        products={state.products}
                        removeItem={removeItem}
                        editProduct={itemProductChange}
                        editQuantity={handleQuantityChange}
                        editSupplier={itemSupplierChange}
                        suppliers={state.suppliers}
                        createProduct={handleCreateNewProduct}
                        createSupplier={handleCreateNewSupplier}
                        editNote={handleNoteChange}
                        handleUploadFile={handleUploadFile}
                    />
                    <br />
                    <Button marginLeft marginRight onClick={addNewItem} > Add Item </Button>
                    <Button marginLeft marginRight onClick={submitUsage} > Submit Shopping List </Button>
                </div>
            </div>
        )
    }
}

export default UsageExternal