import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import Welcome from './pages/Welcome/welcome';
import 'bootstrap/dist/css/bootstrap.min.css';
import UsageExternal from './pages/UsageExternal/usage-external';


function App() {
  return (
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/form/:id" element={<UsageExternal />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
